:root {
  --zkc-disabled-grey: #d2d0d8;
  --zkc-gradient-dark: linear-gradient(130deg, #333262 0%, #000023 100%);
  --zkc-highlight-ui: #6851c6;
  --zkc-disabled-grey-text: #d2d0d8;
  --zkc-grey-ui-outline: #9b98a4;
  --zkc-hover-purple-ui: #f2f0fa;
  --zkc-mid-purple-ui-icon: #6851c6;
  --zkc-light-purple-ui-btm: #f8f6ff;
  --zkc-disabled-grey-ui: #f4f3f8;
  --zkc-light-grey-ui-line: #b6b4bb;
  --zkc-mid-grey-text: #8e8d8f;
  --zkc-black-text: #333;
  --box-shadow-offset: 3px 3px 15px 0;
  --font-family-base: 'Inter', sans-serif;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--font-family-base);
}

#__next {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.appearance-none {
  appearance: none;
  border: none;
  outline: none;
  background: transparent;
  &::-webkit-search-cancel-button {
    display: none;
  }
}

.btn {
  font-size: 0.875rem;
  padding: 0.4375rem 0.5rem;
  border: 0.09375rem solid var(--zkc-highlight-ui) !important;
  line-height: 1.0625rem;

  &.btn-lg {
    padding: 0.375rem 0.9375rem;
    font-size: 1rem;
    line-height: 1.375rem;
  }

  &.btn-sm {
    font-size: 0.75rem;
    line-height: 0.9375rem;
  }

  // Primary - Positive
  &.btn-primary {
    border-radius: 0.25rem;
    color: white;
    border-color: var(--zkc-highlight-ui) !important;
    background: var(--zkc-highlight-ui);

    &:hover,
    &:active {
      box-shadow: var(--box-shadow-offset) rgba(104, 81, 198, 0.4);
      background: var(--zkc-highlight-ui);
    }

    &:disabled {
      border-color: #f1eff4 !important;
      background-color: #f1eff4;
      color: var(--zkc-disabled-grey-text);
    }
  }

  // Primary - Negative
  &.btn-light {
    border-radius: 0.25rem;
    color: var(--zkc-mid-purple-ui-icon);
    border-color: var(--zkc-light-purple-ui-btm) !important;
    background: var(--zkc-light-purple-ui-btm);

    &:hover,
    &:active {
      box-shadow: var(--box-shadow-offset) rgba(104, 81, 198, 0.4);
      background: var(--zkc-light-purple-ui-btm);
    }

    &:disabled {
      border-color: #f1eff4 !important;
      background-color: #f1eff4;
      color: var(--zkc-disabled-grey-text);
    }
  }

  // Hightlight
  &.btn-outline-light {
    border-radius: 0.1875rem;
    color: var(--zkc-mid-purple-ui-icon);
    background-color: var(--zkc-light-purple-ui-btm);
    border-color: var(--zkc-highlight-ui) !important;

    &:hover,
    &:active {
      background-color: var(--zkc-highlight-ui);
      color: white;
    }

    &:disabled {
      opacity: 1;
      border-color: var(--zkc-disabled-grey) !important;
      background-color: white;
      color: var(--zkc-disabled-grey-text);
    }
  }

  // Secondary
  &.btn-secondary {
    border-radius: 0.1875rem;
    color: var(--zkc-mid-purple-ui-icon);
    background: var(--zkc-light-purple-ui-btm);
    border-color: var(--zkc-light-purple-ui-btm) !important;

    &:hover,
    &:active {
      border-color: var(--zkc-highlight-ui) !important;
      background: var(--zkc-light-purple-ui-btm);
      color: var(--zkc-mid-purple-ui-icon);
    }

    &:disabled,
    &.disabled {
      opacity: 1;
      color: var(--zkc-disabled-grey-text);
      background: var(--zkc-disabled-grey-ui);
    }
  }
}

input,
textarea {
  resize: none;
  border: 1px solid var(--zkc-light-grey-ui-line) !important;
  border-radius: 4px;
  font-size: 0.75rem !important;
  color: var(--zkc-black-text);

  &:focus {
    border-color: var(--zkc-highlight-ui) !important;
    box-shadow: none !important;
  }

  &::placeholder {
    font-size: 0.75rem;
    color: var(--zkc-disabled-grey-text) !important;
  }
}

input[type='checkbox'] {
  border: 2px solid #e7e7e7;

  &:active {
    border: 2px solid #e7e7e7;
  }

  &:checked {
    background-color: var(--zkc-mid-purple-ui-icon);
    border-color: var(--zkc-mid-purple-ui-icon);
  }

  &:focus {
    box-shadow: none;
  }
}

.font-size-12 {
  font-size: 0.75rem;
  line-height: 0.9375rem;
}

.font-size-14 {
  font-size: 0.875rem;
  line-height: 1.0625rem;
}

.font-size-18 {
  font-size: 1.125rem;
  line-height: 1.375rem;
}

.text-color-black {
  color: var(--zkc-black-text) !important;
}

.text-highlight {
  color: var(--zkc-highlight-ui);
}

.text-danger {
  color: #ff4040 !important;
}

[contenteditable='true'],
article {
  img {
    max-width: 100%;
  }
}
