.navLink {
  height: auto;
  color: var(--zkc-black-text);
  border-bottom: 2px solid transparent;
  margin: 0;

  @media screen and (min-width: 992px) {
    height: 100%;
    margin: 0 1.875rem 0 0;
  }

  &:global {
    &.active {
      color: var(--zkc-highlight-ui) !important;
      border-bottom-color: var(--zkc-highlight-ui);
    }
  }
}

.button {
  border-color: var(--zkc-highlight-ui) !important;
  border-radius: 3px;
  background-color: var(--zkc-light-purple-ui-btm);
  padding: 0.4375rem 0.5rem;

  .balance {
    color: var(--zkc-mid-purple-ui-icon);
  }

  .icon {
    color: var(--zkc-mid-purple-ui-icon);
  }

  &:global {
    &.connectWalletButton {
      color: var(--zkc-mid-purple-ui-icon);
    }
  }
}

.mainNavigator {
  height: 3.75rem;

  .navbarBrand {
    height: 1.375rem;

    .webTitle {
      color: var(--zkc-mid-purple-ui-icon);
      font-size: 17.361px;
    }
  }

  .navbar {
    height: 100% !important;
  }

  .mainNavigatorButton {
    .button;
  }
}

.offcanvasBody .mainNavigatorButton {
  .button;
}
